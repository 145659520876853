import React from "react";
import { auth } from "../config/firebase-config";
import { logout } from "../config/firebase-config";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Search from "./Search";

const Menu = (props) => {
  let showAdmin = false;
  if (
    auth.currentUser.uid === "2aIshEOZfDN86uezlDQATJK2qHv2" ||
    auth.currentUser.uid === "REpv0gLVbxcaBWAzqCDxA2lvNBF2" ||
    auth.currentUser.uid === "MSv3hmfWx3SqBrZq4oJNahs4Ozp2" ||
    auth.currentUser.uid === "4oW3L41cBHY4jq4t1VeUMLTO9Jj2" ||
    auth.currentUser.uid === "nVC9thVgJfh9sdnb5AocU0a7SLv1" ||
    auth.currentUser.uid === "ncChh6vZsoSgLmYrhaNRRyx5uca2" ||
    auth.currentUser.uid === "8SnVqcFhiUeDXknm48ZAB4SO4cH3"
  ) {
    showAdmin = true;
  }

  return (
    <div className="headerMenu">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#" onClick={() => props.changePage("overview")}>
                Übersicht
              </Nav.Link>
              <Nav.Link href="#" onClick={() => props.changePage("protokoll")}>
                Protokoll
              </Nav.Link>
              {showAdmin ? (
                <Nav.Link
                  href="#"
                  onClick={() => props.changePage("einstellungen")}
                >
                  Einstellungen
                </Nav.Link>
              ) : undefined}
              <Nav.Link href="#" onClick={() => props.changePage("changelog")}>
                Changelog
              </Nav.Link>
              <Nav.Link href="#" onClick={() => logout()}>
                Abmelden
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {props.page === "overview" ||
          props.page === "protokoll" ||
          props.page === "protokollFahrzeug" ? (
            <div className="search">
              <Search
                searchString={props.searchString}
                setSearchString={props.setSearchString}
              />
            </div>
          ) : undefined}
        </Container>
      </Navbar>
    </div>
  );
};

export default Menu;
