import React, { useEffect, useState, useTransition } from "react";

import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-date-picker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MangelColumn from "./MangelColumn";
import MangelNewColumn from "./MangelNewColumn";
import ModalMangelIntern from "./ModalMangelIntern";

import Modal from "react-bootstrap/Modal";

import {
  collection,
  doc,
  setDoc,
  onSnapshot,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { db, auth, logout } from "../config/firebase-config";
import { MdDeleteForever, MdAddCircle } from "react-icons/md";
import { TbLayoutBottombarCollapseFilled } from "react-icons/tb";
import { MdFilterList } from "react-icons/md";
import { MdExpand } from "react-icons/md";
import { MdListAlt } from "react-icons/md";

const ModalFahrzeugHistorie = (props) => {
  const [fahrzeugAkt, setFahrzeugAkt] = useState({});

  const handleClose = () => {
    props.close();
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Fahrzeughistorie</Modal.Title>
        </Modal.Header>

        <Modal.Body></Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFahrzeugHistorie;
