import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "./config/firebase-config";

import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
} from "firebase/firestore";

import Login from "./components/Login";
import Overview from "./components/Overview";
import Header from "./components/Header";
import Einstellungen from "./components/Einstellungen";
import Protokoll from "./components/Protokoll";
import Changelog from "./components/Changelog";
import Fahrzeug from "./components/Fahrzeug";
import ProtokollFahrzeug from "./components/ProtokollFahrzeug";

const App = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [page, setPage] = useState("overview");
  const [pageKennzeichen, setPageKennzeichen] = useState("testkennzeichen ");

  const [fahrzeuge, setFahrzeuge] = useState([]);
  const [funkkennungen, setFunkkennungen] = useState([]);
  const [kategorien, setKategorien] = useState([]);
  const [protokoll, setProtokoll] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [fahrzeugId, setFahrzeugId] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuth(true);
      } else {
        // User is signed out
        setIsAuth(false);
      }
    });
  }, []);

  const changePage = (newPage) => {
    setPage(newPage);
  };

  const gotoFahrzeugPage = (kennzeichen) => {
    setPageKennzeichen(kennzeichen);
    setPage("fahrzeug");
  };

  const fahrzeugeRef = collection(db, "fahrzeuge");
  const funkkennungenRef = collection(db, "funkkennungen");
  const protokollRef = collection(db, "protokoll");
  const kategorienRef = collection(db, "kategorien");

  useEffect(() => {
    const queryFahrzeuge = query(fahrzeugeRef, orderBy("funkkennung"));

    const unsubscribe = onSnapshot(queryFahrzeuge, (snapshot) => {
      let fahrzeugeTmp = [];
      snapshot.forEach((doc) => {
        // console.log("wie oft bin ich hier?");

        fahrzeugeTmp.push({ ...doc.data(), id: doc.id });
      });
      setFahrzeuge(fahrzeugeTmp);
      // console.log("fahrzeugeTmp (APP)", fahrzeugeTmp);
    });

    const queryFunkkennungen = query(funkkennungenRef, orderBy("bezeichnung"));

    const unsubscribe2 = onSnapshot(queryFunkkennungen, (snapshot) => {
      let funkkennungen = [];
      snapshot.forEach((doc) => {
        funkkennungen.push({ ...doc.data(), id: doc.id });
      });
      setFunkkennungen(funkkennungen);
    });

    const queryKategorien = query(kategorienRef, orderBy("bezeichnung"));

    const unsubscribe3 = onSnapshot(queryKategorien, (snapshot) => {
      let kategorien = [];
      snapshot.forEach((doc) => {
        kategorien.push({ ...doc.data(), id: doc.id });
      });
      setKategorien(kategorien);
    });

    return () => {
      unsubscribe();
      unsubscribe2();
      unsubscribe3();
    };
  }, []);

  useEffect(() => {
    // console.log("useEffect aufgeufen (Protokoll.js");
    const queryProtokoll = query(
      protokollRef,
      orderBy("timestamp", "desc"),
      limit(500)
    );
    const unsubscribe3 = onSnapshot(queryProtokoll, (snapshot) => {
      let protokoll = [];
      snapshot.forEach((doc) => {
        protokoll.push({ ...doc.data(), id: doc.id });
      });
      setProtokoll(protokoll);
      // console.log("Protokoll: ", protokoll);
    });

    return () => {
      unsubscribe3();
    };
  }, []);

  // return <></>;

  // console.log("fahrzeuge (APP)", fahrzeuge);

  if (!isAuth) {
    return (
      <Login
        fahrzeuge={fahrzeuge}
        kategorien={kategorien}
        funkkennungen={funkkennungen}
      />
    );
  } else {
    return (
      <>
        <Header
          page={page}
          changePage={changePage}
          searchString={searchString}
          setSearchString={setSearchString}
        />
        {page === "overview" ? (
          <Overview
            fahrzeuge={fahrzeuge}
            funkkennungen={funkkennungen}
            searchString={searchString}
            setSearchString={setSearchString}
            gotoFahrzeugPage={gotoFahrzeugPage}
          />
        ) : undefined}
        {page === "einstellungen" ? <Einstellungen /> : undefined}
        {page === "fahrzeug" ? (
          <Fahrzeug
            fahrzeuge={fahrzeuge}
            kategorien={kategorien}
            funkkennungen={funkkennungen}
            pageKennzeichen={pageKennzeichen}
            changePage={changePage}
            setFahrzeugId={setFahrzeugId}
          />
        ) : undefined}
        {page === "protokoll" ? (
          <Protokoll
            protokoll={protokoll}
            searchString={searchString}
            setSearchString={setSearchString}
          />
        ) : undefined}
        {page === "protokollFahrzeug" ? (
          <ProtokollFahrzeug
            protokoll={protokoll}
            searchString={searchString}
            setSearchString={setSearchString}
            fahrzeugId={fahrzeugId}
            changePage={changePage}
            pageKennzeichen={pageKennzeichen}
          />
        ) : undefined}
        {page === "changelog" ? <Changelog /> : undefined}
      </>
    );
  }
};

export default App;
