import React, { useEffect, useState, useTransition } from "react";

import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-date-picker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MangelColumn from "./MangelColumn";
import MangelNewColumn from "./MangelNewColumn";
import ModalMangelIntern from "./ModalMangelIntern";

import {
  collection,
  doc,
  setDoc,
  onSnapshot,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { db, auth, logout } from "../config/firebase-config";
import { MdDeleteForever, MdAddCircle } from "react-icons/md";
import { TbLayoutBottombarCollapseFilled } from "react-icons/tb";
import { MdFilterList } from "react-icons/md";
import { MdExpand } from "react-icons/md";
import { MdListAlt } from "react-icons/md";
import ModalFahrzeugHistorie from "./ModalFahrzeugHistorie";

const Fahrzeug = (props) => {
  const [fahrzeugAkt, setFahrzeugAkt] = useState({});
  const [funkkennungAkt, setFunkkennungAkt] = useState({});
  const [maengel, setMaengel] = useState([]);
  const [maengelNeu, setMaengelNeu] = useState([]);
  const [maengelOffen, setMaengelOffen] = useState([]);
  const [maengelBacklog, setMaengelBacklog] = useState([]);
  const [maengelErledigt, setMaengelErledigt] = useState([]);
  const [desDateOutput, setDesDateOutput] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [showModalMeldung, setShowModalMeldung] = useState(false);
  const [showModalFahrzeugHistorie, setShowModalFahrzeugHistorie] =
    useState(false);

  useEffect(() => {
    // console.log("hier sind die props", props);
    for (let i = 0; i < props.fahrzeuge.length; i++) {
      if (props.fahrzeuge[i].kennzeichen === props.pageKennzeichen) {
        setFahrzeugAkt(props.fahrzeuge[i]);

        // aktuelle Funkkennung passend zum Fahrzeug hinterlegen
        for (let j = 0; j < props.funkkennungen.length; j++) {
          if (
            props.fahrzeuge[i].funkkennung ===
            props.funkkennungen[j].bezeichnung
          ) {
            setFunkkennungAkt(props.funkkennungen[j]);
          }
        }

        if (props.fahrzeuge[i].desDate) {
          const date = new Date(props.fahrzeuge[i].desDate.seconds * 1000);
          setDesDateOutput(
            date.getDate() + "." + date.getMonth() + "." + date.getFullYear()
          );
        }

        let maengelTmpNeu = [];
        let maengelTmpOffen = [];
        let maengelTmpBacklog = [];
        let maengelTmpErledigt = [];
        if (props.fahrzeuge[i].maengel) {
          for (let j = 0; j < props.fahrzeuge[i].maengel.length; j++) {
            if (props.fahrzeuge[i].maengel[j].new) {
              maengelTmpNeu.push(props.fahrzeuge[i].maengel[j]);
            } else {
              switch (props.fahrzeuge[i].maengel[j].status) {
                case "offen":
                  maengelTmpOffen.push(props.fahrzeuge[i].maengel[j]);
                  break;
                case "backlog":
                  maengelTmpBacklog.push(props.fahrzeuge[i].maengel[j]);
                  break;
                case "erledigt":
                  maengelTmpErledigt.push(props.fahrzeuge[i].maengel[j]);
                  break;
                default:
              }
            }
          }
        }

        setMaengelNeu(maengelTmpNeu);
        setMaengelOffen(maengelTmpOffen);
        setMaengelBacklog(maengelTmpBacklog);
        setMaengelErledigt(maengelTmpErledigt);
      }
    }
  }, [props.pageKennzeichen, props.fahrzeuge]);

  useEffect(() => {
    let maengelTmpNeu = [];
    let maengelTmpOffen = [];
    let maengelTmpBacklog = [];
    let maengelTmpErledigt = [];

    for (let j = 0; j < maengel.length; j++) {
      if (maengel[j].new) {
        maengelTmpNeu.push(maengel[j]);
      } else {
        switch (maengel[j].status) {
          case "offen":
            maengelTmpOffen.push(maengel[j]);
            break;
          case "backlog":
            maengelTmpBacklog.push(maengel[j]);
            break;
          case "erledigt":
            maengelTmpErledigt.push(maengel[j]);
            break;
          default:
        }
      }
    }

    setMaengelNeu(maengelTmpNeu);
    setMaengelOffen(maengelTmpOffen);
    setMaengelBacklog(maengelTmpBacklog);
    setMaengelErledigt(maengelTmpErledigt);
  }, [maengel]);

  useEffect(() => {
    // console.log("useEffect aufgeufen (Protokoll.js");
    const dbRef = "fahrzeuge/" + fahrzeugAkt.id + "/maengel";
    const maengelRef = collection(db, dbRef);

    const queryMaengel = query(maengelRef);
    const unsubscribe3 = onSnapshot(queryMaengel, (snapshot) => {
      let maengel = [];
      snapshot.forEach((doc) => {
        maengel.push({ ...doc.data(), id: doc.id });
      });
      setMaengel(maengel);
      // console.log("maengel: ", maengel);
    });

    return () => {
      unsubscribe3();
    };
  }, [fahrzeugAkt]);

  const handleCancel = () => {
    props.changePage("overview");
  };

  const statusToText = (status) => {
    switch (status) {
      case "einsatzbereit":
        return "Regelbedarf";
      case "technischeReserve":
        return "Reservefahrzeuge";
      case "taktischeReserve":
        return "Spitzenbedarf";
      case "fuehrung":
        return "Führung";
      case "desinfektion":
        return "Desinfektion";
      case "sonderbedarf":
        return "Sonderbedarf";
      case "ausserdienst":
        return "Nicht Dienstfähig";
      default:
        return status;
    }
  };

  const handleModalClose = () => {
    setShowModalMeldung(false);
  };

  const handleModalFahrzeugHistorieClose = () => {
    setShowModalFahrzeugHistorie(false);
  };

  //console.log("maengelNeu", maengelNeu);
  //console.log("fahrzeugAkt log", fahrzeugAkt);
  // console.log("fahrzeug", props);
  return (
    <>
      <ModalMangelIntern
        fahrzeug={fahrzeugAkt}
        funkkennung={funkkennungAkt}
        kategorien={props.kategorien}
        showModal={showModalMeldung}
        close={handleModalClose}
      />
      <ModalFahrzeugHistorie
        showModal={showModalFahrzeugHistorie}
        close={handleModalFahrzeugHistorieClose}
      />
      <Container className="pageFahrzeug">
        <Card className="statusColumn">
          <Card.Header style={{ backgroundColor: "lightgray" }}>
            <div className="settingHeader">
              <div className="settingTitle">
                Fahrzeugdaten: {fahrzeugAkt.kennzeichen} | {fahrzeugAkt.nutzer}
              </div>
              <div>
                <Button
                  variant="secondary"
                  className="btn-header"
                  onClick={() => {
                    props.changePage("protokollFahrzeug");
                    props.setFahrzeugId(fahrzeugAkt.id);
                  }}
                >
                  Fahrzeughistorie
                </Button>
                <Button
                  variant="primary"
                  className="btn-header"
                  onClick={() => {
                    setShowAll(!showAll);
                  }}
                >
                  <MdListAlt />
                </Button>
                <Button
                  variant="success"
                  className="btn-add"
                  onClick={() => {
                    setShowModalMeldung(true);
                  }}
                >
                  <MdAddCircle />
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="content">
            <Container>
              <Form>
                <Row>
                  <Col>
                    <p className="fahrzeugHeading">Status</p>
                    <p className="fahrzeugInfo">
                      {statusToText(fahrzeugAkt.status)}
                    </p>

                    <p className="fahrzeugHeading">Funkkennung</p>
                    <p className="fahrzeugInfo">
                      {fahrzeugAkt.funkkennung === ""
                        ? "Keine"
                        : fahrzeugAkt.funkkennung}
                    </p>

                    <p className="fahrzeugHeading">Standort</p>
                    <p className="fahrzeugInfo">{fahrzeugAkt.standort}</p>
                  </Col>
                  <Col>
                    <p className="fahrzeugHeading">Letzte Grunddesinfektion</p>
                    <p className="fahrzeugInfo">{desDateOutput}</p>

                    <p className="fahrzeugHeading">Kilometerstand</p>
                    <p className="fahrzeugInfo">
                      {fahrzeugAkt.kilometerstand} km
                    </p>

                    <p className="fahrzeugHeading">Bemerkung</p>
                    <p className="fahrzeugInfo">{fahrzeugAkt.bemerkung}</p>
                  </Col>

                  <Col>
                    <MangelNewColumn
                      title="Vorhandene Mängel"
                      bg="#dedede"
                      maengel={maengelNeu}
                      fahrzeug={fahrzeugAkt}
                      funkkennung={funkkennungAkt}
                      kategorien={props.kategorien}
                    />
                  </Col>
                </Row>
              </Form>
            </Container>
            <Container>
              <hr style={{ margin: "15px 0 30px 0" }} />
              <Row>
                <Col>
                  <MangelColumn
                    title="Vorhandene Mängel"
                    bg="#dedede"
                    maengel={maengelOffen}
                    showAll={showAll}
                    fahrzeug={fahrzeugAkt}
                    funkkennung={funkkennungAkt}
                    kategorien={props.kategorien}
                  />
                </Col>
                <Col>
                  <MangelColumn
                    title="Zurückgestellte Mängel"
                    bg="#dedede"
                    maengel={maengelBacklog}
                    showAll={showAll}
                    fahrzeug={fahrzeugAkt}
                    funkkennung={funkkennungAkt}
                    kategorien={props.kategorien}
                  />
                </Col>
                <Col>
                  <MangelColumn
                    title="Erledigte Mängel"
                    bg="#dedede"
                    maengel={maengelErledigt}
                    showAll={showAll}
                    fahrzeug={fahrzeugAkt}
                    funkkennung={funkkennungAkt}
                    kategorien={props.kategorien}
                  />
                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                handleCancel();
              }}
              style={{ marginRight: "5px" }}
              variant="secondary"
            >
              Schließen
            </Button>
            {/* <Button variant="primary" disabled>
              Speichern
            </Button> */}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};

export default Fahrzeug;
