import React, { useState, useEffect } from "react";
import { db } from "../config/firebase-config";
import {
  collection,
  doc,
  addDoc,
  deleteDoc,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";

import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { MdDeleteForever, MdAddCircle } from "react-icons/md";

const EinstellungenMangelKat = (props) => {
  const [modal, setModal] = useState(false);
  const [kategorien, setKategorien] = useState([]);
  const [KategorieNeu, setKategorieNeu] = useState("");

  const kategorieRef = collection(db, "kategorien");

  useEffect(() => {
    const queryKategorie = query(kategorieRef, orderBy("bezeichnung"));

    const unsubscribe = onSnapshot(queryKategorie, (snapshot) => {
      let kategorien = [];
      snapshot.forEach((doc) => {
        kategorien.push({ ...doc.data(), id: doc.id });
      });
      setKategorien(kategorien);
    });

    return () => unsubscribe();
  }, []);

  const handleClose = () => {
    setModal(false);
    setKategorieNeu("");
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleSave = () => {
    const data = {
      bezeichnung: KategorieNeu,
    };

    addDoc(kategorieRef, data)
      .then((docRef) => {
        // console.log("Document Field has been updated successfully");
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    // console.log('delete clicked', id);
    const docRef = doc(db, "kategorien", id);
    deleteDoc(docRef)
      .then(() => {
        // console.log('Eintrag gelöscht');
      })
      .catch((error) => {
        console.log("error");
      });
  };

  let color = "black";
  if (props.color !== undefined) color = props.color;
  return (
    <>
      <Card className="statusColumn">
        <Card.Header
          style={{
            backgroundColor: props.bg,
            color: color,
            textAlign: "center",
          }}
        >
          <div className="settingHeader">
            <div className="settingTitle">{props.title}</div>
            <div>
              <Button
                variant="success"
                className="btn-add"
                onClick={handleOpen}
              >
                <MdAddCircle />
              </Button>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Bezeichnung</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {kategorien.map((kategorie) => {
                return (
                  <tr key={kategorie.id}>
                    <td>{kategorie.bezeichnung}</td>
                    <td>
                      <MdDeleteForever
                        className="btn-delete"
                        onClick={() => {
                          handleDelete(kategorie.id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kategorie hinzufügen</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Bezeichnung</Form.Label>
            <Form.Control
              type="text"
              value={KategorieNeu}
              onChange={(event) => setKategorieNeu(event.target.value)}
              placeholder="Bezeichnung eingeben"
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EinstellungenMangelKat;
