import React from "react";

import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const Changelog = () => {
  const versions = [
    {
      version: "0.1.21",
      datum: "XX.11.2024",
      changes: [
        "Anpassung Login: Mangelformular hinzugefügt",
        "Neue Ansicht Fahrzeugdetails ist jetzt zu erreichen mit Klick auf Fahrzeugkennzeichen aus der Übersicht",
      ],
    },
    {
      version: "0.1.20",
      datum: "02.09.2024",
      changes: [
        "Textanpassung: Regeldienst -> Regelbedarf",
        "Textanpassung: Taktische Reserve -> Spitzenbedarf",
      ],
    },
    {
      version: "0.1.19",
      datum: "22.08.2024",
      changes: [
        "Neue Spalte 'Führung' hinzugefügt",
        "Der Zeitstempel (Stand) auf den Fahzeugkarten wird nur noch aktualisiert, wenn sich der Status (Spalte) des Fahrzeugs ändert",
        "Darstellung auf der Übersicht angepasst. (Breite)",
      ],
    },
    {
      version: "0.1.18",
      datum: "18.07.2024",
      changes: [
        "Verbesserung Performance: Ladezeit beim Wechsel zwischen Übersicht und Protokoll deutlich verbessert",
      ],
    },
    {
      version: "0.1.17",
      datum: "12.07.2024",
      changes: [
        "Neues optionales Feld Kilometer hinzugefügt",
        "Grundlegende Möglichkeit zum E-Mailversand durch das System vorbereitet",
        "E-Mail: Bei jedem Fahrzeugupdate wird jetzt eine E-Mail an einen vordefinierten Empfänger mit den Daten zum Update geschickt",
      ],
    },
    {
      version: "0.1.16",
      datum: "04.07.2024",
      changes: [
        "Suchfunktion in der Übersicht eingefügt. Es durchsucht die Felder Funkkennung, Kennzeichen, Standort und Bemerkung",
        "Suchfunktion im Protokoll eingefügt. Es durchsucht die Felder Funkkennung, Fahrzeug, Standort und Bemerkung",
        "Protokoll: Neues Feld Funkkennung (Vorher) eingefügt",
        "Darstellung Protokoll korrigiert (Breite angeglichen)",
        "Fehler Plausibilitätsprüfung korrigiert (Speichern trotz korrekter Eingaben nicht möglich)",
        "Leichte Verbesserung Performance (Protokoll)",
      ],
    },
    {
      version: "0.1.15",
      datum: "27.06.2024",
      changes: [
        "Changelog ab jetzt für alle Benutzer verfügbar",
        "Information letzte Grunddesinfektion hinzugefügt. Datum wird in der Übersicht rot dargestellt, wenn es länger als 7 Tage her ist. Information wird bereits mit im Protokoll (Datenbank) gespeichert, aber noch nicht im Protokoll angezeigt",
        "Erweiterung der Standorte um die Eigenschaft Typ. Feld wurde ebenfalls in den Einstellungen hinzugefügt",
        "Plausibilitätsprüfung Funkkennung eingefügt: Regeldienst, Taktische Reserve & Sonderbedarf müssen eine Funkkennung haben, bei den anderen Spalten wird sie automatisch entfernt",
        "Palusibilitätsprüfung Standorte eingefügt: Regeldienst, Taktische Reserve & Sonderbedarf müssen einen Dienstort ausgewählt haben",
        "Plausibilitätsprüfung Bemerkung eingefügt: Das Feld muss ab sofort bearbeitet werden und darf nicht mehr leer sein",
      ],
    },
    {
      version: "0.1.14",
      datum: "21.06.2024",
      changes: [
        "Im Login zusätzlich die Kontaktadresse admin@fwhb.fleethq.de eingeblendet",
        "Drag&Drop Verhalten geändert: Erst mit Sepeichern wandert das Fahrzeug in die neue Spalte. Bei Abbrechen verbleibt es in ursprünlicher Spalte",
        "Fehlerbehebung: Es wird wieder der korrekte Status protokolliert",
      ],
    },
    {
      version: "0.1.13",
      datum: "19.06.2024",
      changes: [
        'Anpassung Speichern Fahrzeugdaten: Ab sofort werden die Daten erst mit dem Klick auf den neuen Button "Speichern" aktualisiert. Das Speichern erfolgt also nicht mehr automatisch!',
        "Fehlerbehebung bei der Freigabe der Funkkennungen. Diese werden nun korrekt wieder zur Verfügung gestellt",
        "Vorgegebene Sortierung der Spalten angepasst: 1. Ordnung Funkkennung, 2. Ordnung Kennzeichen",
        "Feedback bei fehlerhaften Login eingebaut",
        "Geschwindigkeit verbessert beim öffnen (Doppelklick) einer Fahrzeugkarte",
        "Umbennenung Spalte 'Technische Reserver' zu 'Reservefahrzeuge'",
      ],
    },
  ];

  return (
    <div className="Changelog">
      <Container>
        <Card className="statusColumn">
          <Card.Header style={{ backgroundColor: "lightgray" }}>
            Changelog
          </Card.Header>
          <Card.Body>
            {versions.map((item, index) => {
              return (
                <div key={index}>
                  <h4>Version: {item.version}</h4>
                  <h5 style={{ color: "gray" }}>Datum: {item.datum}</h5>
                  <ul>
                    {item.changes.map((change, index) => {
                      return <li key={index}>{change}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Changelog;
